/** @jsx jsx */

import {useState} from 'react'
import Link from '../components/Link'
import MySEO from '../components/seo'
import {Box,jsx,Container,Grid,Text,Link as ThLink} from 'theme-ui'
import Layout from '../components/layout'
import {GatsbyImage} from 'gatsby-plugin-image'
import {graphql} from 'gatsby'

export default function Salons({data}){
  const [news,setNews] = useState(false)
  //const cm = useColorMode()
 
  return(
    
    <Layout >
    {
        news &&
        <Container
         sx={{position:'absolute',display:'flex',flexDirection:'column', justifyContent:'flex-start',width:[320,600,600,800],height:1000,top:365,zIndex:300,right:0,left:0}}
        >
          <Box 
           sx={{color:'white',cursor:'pointer'}}
           onKeyDown={()=>setNews(!news)} onClick={()=>setNews(!news)} as="Button" 
          >Newsletter-Formular schließen
          </Box>
       
          <iframe title="Newsletter-Anmeldeformular" width="100%" height="800"  src="https://e637a942.sibforms.com/serve/MUIEAK2nWTixVVm70Bak5JNdhAOOZGB2YtURdD6Dvq5I4_-ugscyK4mQ024n6tuLyuQFjZstAgY90gyAUBTRQgNYlcOgrWBeuLwEACN1Da_O8KFVbp7o54t4xprwQ7zIGeJP7yPAP6s3Y6Ga72_ifeR-3xmtqlR5pPitVOOD2tOt_vx4uTbBjrb9pR2OKt1FedUsdghnAOmaU41x" frameborder="0" scrolling="auto" allowfullscreen style={{display: 'block',marginLeft:'auto',marginRight: 'auto',maxWidth: '900'}}></iframe>
         
        </Container>
      }
      
    <MySEO title="Salons" description="K.O.M organisiert Salons mit Live-Musik. Auf den Events sind Spenden erwünscht für kulturelle Zwecke in der Region Oberes Mittelrheintal" />

      <Box mt="3">
          <h1 sx={{fontSize:[4,5],fontWeight: 300, 
            letterSpacing:"0.09rem",color:`text`}}>
                <span style={{fontWeight:800, letterSpacing:'-0.1rem'}}>K.O.M.</span>-Salons 2023
            </h1>

 
</Box>


<Grid sx={{marginBottom:40}} columns={[1,1,2]} gap={3}>

<Box  sx={{borderTop:"1px solid grey",padding:"34px 0px",width:"100%", margin:"3px 0px"}} >
 <h2 sx={{fontSize:[1,1,1,2],color:'text',fontWeight:700}}>Salon N° 6 // 23. Juni 2023, Beginn 19:00 Uhr<br/><span sx={{fontWeight:300}}>Foyer in der Rheinfelshalle</span> 

</h2> 

 <Box> 

   
 <GatsbyImage image={data.frei.childImageSharp.gatsbyImageData} alt="Freiligrath"  />

 <p sx={{color:'text',lineHeight:'1', mt:2,pb:3 ,fontWeight:300,fontStyle:'italic',fontSize:0}}>Bild:  ©gemeinfrei</p>
</Box>
<Link to="/allesalons/sechstersalon">
 <Text>
 &rarr; Schauspiel mit Musik: Ferdinand Freiligrath - Trotz alledem und alledem! 
      </Text>
      </Link>

 
  
</Box>






</Grid>
<Box mt="3">
          <h1 sx={{fontSize:[4,5],fontWeight: 300, 
            letterSpacing:"0.09rem",color:`text`}}>
                <span style={{fontWeight:800, letterSpacing:'-0.1rem'}}>K.O.M.</span>-Salons 2022
            </h1>

 
</Box>
<Grid sx={{marginBottom:40}} columns={[1,1,2]} gap={3}>



<Box  sx={{borderTop:"1px solid grey",padding:"34px 0px",width:"100%", margin:"3px 0px"}} >
 <h2 sx={{fontSize:[1,1,1,2],color:'text',fontWeight:700}}>Salon N° 3 // 11. Dezember, Beginn 16:30 Uhr<br/><span sx={{fontWeight:300}}> Kulturhaus Oberwesel</span>

</h2> 

 <Box sx={{display:'flex',flexDirection:'column',flexShrink:1,flexGrow:1,maxHeight:440,overflow:"hidden",aspectRatio:1.3}}> 

   
 <GatsbyImage image={data.sal3.childImageSharp.gatsbyImageData} alt="Hafen" />

 <p sx={{color:'text',lineHeight:'1', mt:2,pb:3 ,fontWeight:300,fontStyle:'italic',fontSize:0}}>Foto:  ©Benjamin Reiter</p>
</Box>
<Link to="/allesalons/drittersalon">
 <Text>
 &rarr; Zwischen Klassik u. Moderne 
      </Text>
      </Link>


 
  
</Box>


<Box  sx={{borderTop:"1px solid grey",padding:"34px 0px",width:"100%", margin:"3px 0px"}} >
 <h2 sx={{fontSize:[1,1,1,2],color:'text',fontWeight:700}}>Salon N° 5 // 2. Oktober, Beginn 18:00 Uhr<br/><span sx={{fontWeight:300}}> Funboat Marina im Stadthafen St. Goar</span>

</h2> 

 <Box sx={{display:'flex',flexDirection:'column',flexShrink:1,flexGrow:1,aspectRatio:1.3, maxHeight:440,  overflow:"hidden"}}> 

   
 <GatsbyImage image={data.sal5.childImageSharp.gatsbyImageData} alt="Hafen" />

 <p sx={{color:'text',lineHeight:'1', mt:2,pb:3 ,fontWeight:300,fontStyle:'italic',fontSize:0}}>Foto: Funboat Marina im Stadthafen St. Goar | ©Richard Vogel</p>
</Box>
<Link to="/allesalons/fuenftersalon">
 <Text>
 &rarr; Meet &amp; Chill: Jazz und Lesung 
      </Text>
      </Link>

 
  
</Box>
  <Box sx={{borderTop:"1px solid grey",padding:"34px 0px",width:"100%", margin:"3px 0px"}}>
  <h2  sx={{color:'text',fontSize:[1,1,1,2]}}>Salon N° 4 // 26. August<br/><span sx={{fontWeight:300}}>Galerie Stadtmühle in St. Goarshausen</span>
</h2>
 <Box sx={{display:'flex',flexDirection:'column'}}> 
  
      
     {/* <Text  variant='titel'>
     mit den Reisenden Karl Baedeker, Alexander von Humboldt, Alexandra David Néel und anderen
     </Text> */}
 <GatsbyImage image={data.btaw.childImageSharp.gatsbyImageData} alt="Taw" />

 
<p sx={{color:'text',lineHeight:'1', mt:2,pb:3 ,fontWeight:300,fontStyle:'italic',fontSize:0}}> Foto: Szenenbild von Beatrice Hutter und Stephan Rumphorst ©taw</p>
<Link to="/allesalons/viertersalon">
 <Text>
      &rarr; Von der Sehnsucht anzukommen und der Hoffnung nie da zu sein
      </Text>
      </Link>

</Box>
 

 
</Box>




</Grid>
<h1 sx={{fontSize:[4,5],fontWeight: 300, 
            letterSpacing:"0.09rem",color:`text`,paddingTop:40}}>
                <span style={{fontWeight:800, letterSpacing:'-0.1rem'}}>K.O.M.</span>-Salons 2021
            </h1>


<Grid sx={{marginBottom:40}} columns={[1,1,2]} gap={3}>
  <Box sx={{borderTop:"1px solid grey",padding:"34px 0px", margin:"3px 0px"}}>
  <h2  sx={{color:'text',fontSize:[1,1,1,2]}}>Salon N° 2 // 3. Oktober 2021<br/><span sx={{fontWeight:300}}> im Blüchermuseum in Kaub</span>
</h2>
 <Box sx={{display:'flex',flexDirection:'column',flexShrink:0}}> 
 
 <GatsbyImage image={data.mannette.childImageSharp.gatsbyImageData} alt="Eingang Veranstaltungsort" />
 
<p sx={{width:['99%','76%',"93%"],color:'text',lineHeight:'1', mt:2,pb:3 ,fontWeight:300,fontStyle:'italic',fontSize:0}}> Schlussbild, rechts oben Annette Seyfried und ihre Schülerinnen (Clara Holdenried, Viola |
Emma Mühlnickel, Violine)</p>

</Box>
 

<Link  to="/allesalons/bilder-salon2"><ThLink>Weitere Informationen zum Salon N° 2</ThLink></Link> 
</Box>
<Box sx={{borderTop:"1px solid grey",padding:"34px 0px", margin:"3px 0px"}}>
<h2 sx={{fontSize:[1,1,1,2],color:'text',fontWeight:700}}>Salon N° 1 // 24. Juli 2021<br/><span sx={{fontWeight:300}}> Galerie Stadtmühle in St. Goarshausen</span>
</h2>
 <Box sx={{display:'flex',flexDirection:'column',flexShrink:0}}> 
 
<GatsbyImage image={data.klomann.childImageSharp.gatsbyImageData} alt="Eingang Veranstaltungsort" />
<p sx={{color:'text',lineHeight:'1', mt:2,pb:3 ,fontWeight:300,fontStyle:'italic',fontSize:0}}>Peter Klohmann am Saxophon und Frank Reichert am E-Piano</p>

</Box>


<Link  to="/allesalons/ersterSalon"><ThLink>Weitere Informationen zum Salon N° 1</ThLink></Link> 
  
</Box>




</Grid>


<Grid columns={[1,1,2]} gap={3}>
      <Box>
<Text>
Seit Juli 2021 veranstalten wir <span sx={{fontWeight:600}}>regelmäßige K.O.M.-Salons</span> mit abwechslungsreichem 
Kulturprogramm an unterschiedlichen Orten des Oberen Mittelrheintals. Diese Salons sollen der Vernetzung der regionalen Kulturakteur:innen dienen sowie interessante Veranstaltungsorte rechts und links des Rheins vorstellen. Wir freuen uns über konstruktiven Austausch und Synergieeffekte. Wer regelmäßig über unsere Salons informiert werden möchte, kann hier unseren Salon-Newsletter abonnieren.



 </Text>
 
<button sx={{color:'white',mt:3,mb:4,cursor:'pointer',border:'none',background:'tomato'}} onClick={()=>setNews(!news)}>SALON-Newsletter abonnieren</button>
</Box>
<Box>
 <Text>
 Bei der Vermittlung junger, regionaler Musiker*innen sind uns <strong>Angelika Hollmann </strong>
(Landesmusikakademie RLP) und <strong>Frank Reichert</strong> (Leiter des Jugendjazzorchesters
 Rheinland-Pfalz) behilflich.<br/>
Gefördert wurden die beiden ersten Salons über die  <strong>LAG Welterbe Oberes Mittelrheintal</strong> . Mit den Salons möchten wir den Austausch zwischen Kulturakteur:innen und Bewohner:innen sowie Gästen der Region fördern.<br/>
</Text>
</Box>
</Grid>

</Layout>

  )
}

export const MGquery = graphql`
  query {
    frei:file(relativePath: { eq: "salons/salon6-1.jpeg" }) {
      childImageSharp {
        # Specify the image processing specifications right in the query.
        # Makes it trivial to update as your page's design changes.
        gatsbyImageData(
        
          layout: CONSTRAINED
          placeholder: BLURRED
          height:500
        
        )
      }
    }
    btaw:file(relativePath: { eq: "salons/salon2-4.jpeg" }) {
      childImageSharp {
        # Specify the image processing specifications right in the query.
        # Makes it trivial to update as your page's design changes.
        gatsbyImageData(
        
          layout: CONSTRAINED
          placeholder: BLURRED
        
        )
      }
    }
    mannette:file(relativePath: { eq: "salons/gruppe.jpeg" }) {
      childImageSharp {
        # Specify the image processing specifications right in the query.
        # Makes it trivial to update as your page's design changes.
        gatsbyImageData(
     
          layout: CONSTRAINED
          placeholder: BLURRED
        
        )
      }
    }
  klomann:file(relativePath: { eq: "salons/salon1-2.jpg" }) {
      childImageSharp {
        # Specify the image processing specifications right in the query.
        # Makes it trivial to update as your page's design changes.
        gatsbyImageData(
          
          layout: CONSTRAINED
          placeholder: BLURRED
        
        )
      }
    }
    sal5:file(relativePath: { eq: "salons/salon5/marina.jpg" }) {
      childImageSharp {
        # Specify the image processing specifications right in the query.
        # Makes it trivial to update as your page's design changes.
        gatsbyImageData(
         
          layout: CONSTRAINED
          placeholder: BLURRED
        
        )
      }
    }
    sal3:file(relativePath: { eq: "salons/salon3/konzertkulturhaus.jpeg" }) {
      childImageSharp {
        # Specify the image processing specifications right in the query.
        # Makes it trivial to update as your page's design changes.
        gatsbyImageData(
          height:398  
          layout: CONSTRAINED
          placeholder: BLURRED
        
        )
      }
    }
  }   
`
